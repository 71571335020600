import { assign } from "lodash";

export default interface ProductionState {
  orderToAdd: WorkOrder | null;
  workOrders: WorkOrder[];
  temporalWorkOrderList: WorkOrder[];
}

export interface WorkOrder {
  wo_id: string;
  wo_date: string;
  part_no: string;
  wo_qty: string;
  status: string;
  wip_loc: string;
  rel_date: string;
  req_date: string;
  stock_loc: string;
  ls_loc: string;
  description: string;
  schedule_method: string;
  routing: string;
  complete_to_loc: string;
  allocate: string;
  plan_group: string;
  backflush_labor: string;
  oper_items: OperItem[];
  notes: string;
}

export interface OperItem {
  oper: string;
  work_center: string;
  run_hours: string;
  setup_hours: string;
  per_oper_hours: string;
  post_oper_hours: string;
  oper_start_date: string;
  oper_comp_date: string;
  oper_start_time: string;
  oper_comp_time: string;
  operation_desc?: string;
  curr_oper_qty: string;
}

export class Operation {
  oper = "";
  work_center = "";
  run_hours = "";
  setup_hours = "";
  per_oper_hours = "";
  post_oper_hours = "";
  oper_start_date = "";
  oper_comp_date = "";
  oper_start_time = "";
  oper_comp_time = "";
  operation_desc = "";
  curr_oper_qty = "";

  initFromOperItem(op: OperItem): Operation {
    return assign(this, op);
  }

  get totalRunTime(): number {
    const run_hours = this.run_hours ? parseFloat(this.run_hours) : 0;
    const setup_hours = this.setup_hours ? parseFloat(this.setup_hours) : 0;
    const per_oper_hours = this.per_oper_hours
      ? parseFloat(this.per_oper_hours)
      : 0;
    const post_oper_hours = this.post_oper_hours
      ? parseFloat(this.post_oper_hours)
      : 0;
    return setup_hours + per_oper_hours + run_hours + post_oper_hours;
  }
}
