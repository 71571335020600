
import { defineComponent } from "vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import dayjs from "dayjs";
import Utils from "@/utility/utils";

import { WorkCenter } from "@/types/workCenter";
import { mapGetters, mapActions } from "vuex";
import { WorkOrder } from "@/types/state/production";

export default defineComponent({
  components: {
    Button,
    Calendar,
    InputText,
    Dropdown,
  },
  props: {
    barSelected: {
      type: Object,
      required: true,
    },
    isGenericBar: {
      type: Boolean,
      required: true,
    },
    showAddOperationButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    workOrder: {
      type: Object,
      required: true,
    },
    workCenterList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ["saveBarData", "removeBar", "addOperation", "undoChanges"],
  data() {
    return {
      barSelectedLocal: JSON.parse(JSON.stringify(this.barSelected)),
      workOrderLocal: JSON.parse(JSON.stringify(this.workOrder ?? {})),
      invalidFields: [] as Array<string>,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedWorkOrders: "workorder/getSelectedWorkOrders",
    }),
    estRunTime(): string {
      const diffTime = dayjs(this.barSelectedLocal.endDate).diff(
        dayjs(this.barSelectedLocal.startDate),
        "minutes",
      );
      if (diffTime <= 0) {
        return "0 hours";
      } else if (diffTime < 60) {
        return `${diffTime} ${diffTime === 1 ? "minute" : "minutes"}`;
      } else if (diffTime >= 60 && diffTime % 60 === 0) {
        return `${Math.floor(diffTime / 60)} ${
          Math.floor(diffTime / 60) === 1 ? "hour" : "hours"
        }`;
      } else {
        return `${Math.floor(diffTime / 60)} ${
          Math.floor(diffTime / 60) === 1 ? "hour" : "hours"
        } ${diffTime % 60} ${diffTime % 60 === 1 ? "minute" : "minutes"}`;
      }
    },
    showUndoButton(): boolean {
      const workOrder = this.getSelectedWorkOrders.find(
        (item: any) => item.record.wo_id === this.workOrderLocal.wo_id,
      );
      const operation = workOrder?.oldRecord.oper_items.find(
        (item: any) => item.oper === this.barSelectedLocal.oper,
      );

      if (!operation) {
        return false;
      }

      return this.barSelectedLocal.modified;
    },
  },
  mounted() {
    this.barSelectedLocal.startDate = dayjs(
      this.barSelectedLocal.startDate,
    ).toDate();
    this.barSelectedLocal.endDate = dayjs(
      this.barSelectedLocal.endDate,
    ).toDate();
    this.$nextTick(() => {
      (this.$refs.barSelectedLocalInputText as any).$el.focus();
    });
  },
  methods: {
    ...mapActions({
      updateSelectedWorkOrder: "workorder/updateSelectedWorkOrder",
    }),
    dateSelected(event: any, dateType: string) {
      if (
        dayjs(this.barSelectedLocal.endDate).isBefore(
          this.barSelectedLocal.startDate,
        )
      ) {
        this.invalidFields.push("endDate");
      } else {
        this.invalidFields = [];
      }
    },
    isWorkOrderEditable(workOrder: WorkOrder) {
      if (this.isGenericBar) {
        return true;
      }

      const operationId = this.barSelectedLocal.oper;
      const operation = workOrder.oper_items.find(
        (item: any) => item.oper === operationId,
      );

      if (!operation) {
        return false;
      }

      const finishedQuantity = parseFloat(operation.curr_oper_qty) === 0;

      return !finishedQuantity;
    },
    addWorkOrderToSchedule() {
      this.updateSelectedWorkOrder({
        record: this.workOrderLocal,
        oldRecord: JSON.parse(JSON.stringify(this.workOrderLocal)),
      });
    },
    onSaveBarData() {
      this.$emit("saveBarData", this.barSelectedLocal, this.workOrderLocal);
    },
    onRemoveBar() {
      this.$emit("removeBar", this.barSelectedLocal, this.workOrderLocal);
    },
    selectedWorkCenterLabel(value: string): string {
      const workCenter = (this.workCenterList as Array<WorkCenter>).find(
        (item: any) => item.work_center === value,
      );
      return workCenter?.description || "";
    },
    dotColor(workOrderId: any) {
      const workCenterIndex = (
        this.workCenterList as Array<WorkCenter>
      ).findIndex(
        (center) => workOrderId && center.work_center === workOrderId,
      );
      if (workCenterIndex >= 0) {
        return Utils.getColorByIndex(workCenterIndex);
      }
      return "#BBB";
    },
    undoChanges() {
      this.$emit("undoChanges", this.barSelected, this.workOrderLocal);
    },
    addOperation() {
      this.$emit("addOperation");
    },
  },
});
